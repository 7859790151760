import React from 'react';
import StaticLayout from 'components/layouts/static-layout';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import auth from 'utils/auth';
import {ColorBox, Container, P, Row, Wave} from 'components/wrappers';
import Button from 'components/button/button';
import {HERO_COLOR, HERO_BG_COLOR, HEADER_BG_COLOR} from 'assets/theme';

const Home = ({data, location}) => {
  const {title, siteUrl, environment} = data.site.siteMetadata;
  return (
    <StaticLayout
      {...{
        bgColor: HERO_BG_COLOR,
        location,
        isStaticPage: true,
        seo: {title: `${title} home`, description: `Home page for ${siteUrl}`},
      }}
    >
      <ColorBox bgColor={HERO_BG_COLOR} color={HERO_COLOR}>
        <Container hero>
          <h1 data-environment={environment} data-auth0={process.env.GATSBY_AUTH0_NAME}>
            Building Web Applications <br />
            of the future
          </h1>
          <P l>Let&apos;s have a conversation about what we can build for you.</P>
          <Button to="/contact" primary>
            Get in touch
          </Button>
        </Container>
      </ColorBox>
      <Wave bgColor={HERO_BG_COLOR} />
      <ColorBox bgColor={HEADER_BG_COLOR} color={HERO_COLOR}>
        <Container heroPadding>
          <h2 className="text-center">What we do</h2>
          <br />
          <br />
          <Row>
            <div className="col-lg-6">
              <h3>Consultancy</h3>
              <br />
              <P l>
                We specialise in web development using modern web technologies such as{' '}
                <a href="https://reactjs.org/">React.js</a> and{' '}
                <a href="https://graphql.org/">GraphQL</a>.
              </P>
              <P l>We are available on a consultancy basis.</P>
              <P l>
                <Button to="/contact" anchor>
                  Get in touch
                </Button>
              </P>
            </div>
          </Row>
          <br />
          <br />
          <Row>
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <h3>Freelance Web Development</h3>
              <P l>
                Have an idea in mind? Whether its a personal website or a automated system
                for your business, we can build it for you.
              </P>
              <P l>
                <Button to="/contact" anchor>
                  Get in touch
                </Button>
              </P>
            </div>
          </Row>
          <br />
          <br />
        </Container>
      </ColorBox>
      <Wave bgColor={HERO_BG_COLOR} flip />
      <ColorBox bgColor={HERO_BG_COLOR} color={HERO_COLOR}>
        <Container heroPadding>
          <Row>
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <h2>See it in Action!</h2>
              <br />
              <P l>Watch the video to see a demo of the BearJS platform</P>
            </div>
          </Row>
        </Container>
      </ColorBox>
    </StaticLayout>
  );
};

Home.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default Home;

export const GATSBY_QUERY = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        environment
      }
    }
  }
`;
